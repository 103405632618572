<template>
    <div style="height:100%;" class="yj-conten-div">
        <a-ConfigProvider :locale="this.$zhCN">
            <a-layout style="height:100%;">
                <a-layout-content class="yj-conten-div_cont">
                    <div class="yj-conten"
                         style="background:#fff;padding:20px 20px 0;min-height: calc(100% - 48px);">
                        <a-form layout="horizontal" style="padding: 0 0 10px 0px ;height:auto;">
                        </a-form>
                        <a-table :columns="columns" bordered
                                 :data-source="BatchList"
                                 :pagination="pagination"
                                 class="yj-table-img"
                                 @change="pagerChange"
                                 rowKey="BatchID">
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-ConfigProvider>
    </div>
</template>

<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    const columns = [
        {
            title: "订单号",
            dataIndex: "BatchID",
            width: 120
        },
        {
            title: "商品名称",
            dataIndex: "ProductName",
            width: 100,
            ellipsis: true
        },
        {
            title: "商品规格",
            dataIndex: "SpecsInfo",
            width: 100,
            ellipsis: true
        },
        {
            title: "金额",
            dataIndex: "PerPrice",
            width: 110,
        },
        {
            title: "数量",
            dataIndex: "BuyCount",
            width: 110,
        },
        {
            title: "支付金额",
            dataIndex: "LittleSumMoney",
            width: 110,
        },
        {
            title: "支付方式",
            dataIndex: "PayProviider",
            width: 110,
        },
        {
            title: "支付时间",
            dataIndex: "PayTime",
            width: 110,
        },
        {
            title: "支付类型",
            dataIndex: "PayStatus",
            width: 100,
            customRender: (text, item) => {
                var rt;
                switch (item.PayStatus) {
                    case 0:
                        rt = '未支付';
                        break;
                    case 1:
                        rt = '已支付';
                        break;
                    case 4:
                        rt = '已退款';
                        break;
                }
                return rt;
            }
        },
        {
            title: "下单时间",
            dataIndex: "InputTime",
            width: 120
        },
     
    ];
    export default {
        name: "List",
        data() {
            return {
                BatchList: [],
                PayStatus: "-1",//订单支付状态
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 }
                    }
                },
                OrderID: "",
                columns,
                BatchID: "",
                ProductName: "",
                BeginTime: "",
                Phone:"",
                EndTime: "",
                IName: "",
                Time: [],
                PaystatusEnum: []
            }
        },
        methods: {
         
            //页数跳转
            pagerChange: function (item) {
                let self = this;
                self.pagination.current = item.current;
                self.GetBatchOrderData();
            },
            //获取批量订单列表
            GetBatchOrderData() {
                let self = this;
                let op = {
                    url: "/ShopModule/Order/GetBatchOrderDetail",
                    data: {
                        BatchID: this.$route.query.ID,
                        PageIndex: self.pagination.current,
                    },
                    OnSuccess: function (res) {
                        res.data.Data.forEach(element => {
                            element.PerPrice = parseFloat(element.PerPrice).toFixed(2);
                            element.LittleSumMoney = parseFloat(element.LittleSumMoney).toFixed(2);
                            element.InputTime = util.TimeStamptoDateTime(element.InputTime, 'yyyy-MM-dd hh:mm:ss');
                            if (element.PayTime != null)
                            element.PayTime = util.TimeStamptoDateTime(element.PayTime, 'yyyy-MM-dd hh:mm:ss');
                        });
                        self.BatchList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
          


        },
        mounted() {
            //加载列表数据
            this.GetBatchOrderData();
        }
    }
</script>

<style scoped>
</style>